import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { getContentfulUrl } from '../shared/utilities'

const handleClickCTA = partnerName => {
  trackCustomEvent({
    category: 'Partners',
    action: 'Click',
    label: `${partnerName}`
  })
}

const Partners = ({ pageContext, location }) => {
  const { id, name, email, website, phone, callToActionLink, logo, body } = pageContext

  const imgQuery = '?w=700&h=300'
  const imgUrl = getContentfulUrl(logo) + imgQuery

  return (
    <Layout path={location.pathname}>
      <SEO title={name} />
      <div className="container partners text-content">
        <h1>{name}</h1>
        <img src={imgUrl} alt={name} />
        {email && (
          <p>
            Email: <a href={`mailto:${email}`}>{email}</a>
          </p>
        )}
        {phone && (
          <p>
            Phone: <a href={`tel:${phone}`}>{phone}</a>
          </p>
        )}
        <p>
          Website: <a href={website}>{website}</a>
        </p>
        {documentToReactComponents(body.json)}
        <a
          className="btn btn-secondary"
          onClick={() => handleClickCTA(`${name}`)}
          type="button"
          href={callToActionLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit Website
        </a>
      </div>
    </Layout>
  )
}

export default Partners

Partners.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired
}
